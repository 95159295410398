<template>
  <div class="contain">
    <div class="contain-swiper" style="background-color: var(--theme_bg-color);background-image:var(--theme_bg-img) !important;">
      <div class="swiper-header">参赛选手名单</div>
      <div class="swiper-center">
        <div class="content-student" id="scrollArea">
          <div class="student-box" v-for="(item,index) in playerList" :key="item.id">
            <img v-if="item.avatarUrl" :src="item.avatarUrl" alt="">
            <i v-if="item.avatarUrl" style="color: var(--theme_font-color);font-size:  var(--theme_font-size);">{{index + 1}}</i>
            <span v-else class="active" >
              <i style="font-size:  var(--theme_font-size);font-weight: bold"> {{ item.name }}</i>
              <i style="color: var(--theme_font-color);font-size:  var(--theme_font-size);">{{index + 1}}</i>
              
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {findById, getPlayerList} from "../../api/event";

export default {
  name: "drawSort",
  data() {
    return {
      eventInfo: null,
      roleFlag: null,
      playerList: [],
      pageIndex: 1
    }
  },
  sockets: {
    // 连接成功
    onopen() {
      console.log("连接websocket成功");
    },
    // 接收消息
    onmessage(e) {
      this.getEventInfo(this.eventInfo.id)
      this.getPlayerList(this.eventInfo.id)
    },
    // 连接报错
    onerror() {
      console.log("连接websocket出错");
    },
    // 关闭连接
    onclose() {
      console.log("websocket关闭");
    },
  },
  mounted() {
    //1管理 2评委 3主持 4大屏端
    this.roleFlag = sessionStorage.getItem('roleFlag');
    let scrollArea = document.getElementById("scrollArea");
    setInterval(function() {
      // 每次滚动的距离，可以根据需求进行调整
      scrollArea.scrollTop += 3;
      const scrollTop = scrollArea.scrollTop; // 滚动条顶部到可见区域顶部的距离
      const offsetHeight = scrollArea.offsetHeight; // 可见区域的高度
      const scrollHeight = scrollArea.scrollHeight; // 盒子总高度
      if (scrollTop + offsetHeight >= scrollHeight) {
        scrollArea.scrollTop  = 0;
      }
    }, 200);
  },
  created() {
    this.eventInfo = JSON.parse(sessionStorage.getItem('event'))
    this.getEventInfo(this.eventInfo.id)
    this.getPlayerList(this.eventInfo.id)
  },
  methods: {
    //查询选手
    async getPlayerList(id) {
      let obj = {
        eventId: id,
        pageIndex: this.pageIndex
      }
      const {data} = await getPlayerList(obj)
      if (data.code === 200) {
        this.playerList = data.data
      }
    },
    //查询比赛信息
    async getEventInfo(id) {
      const {data} = await findById(id);
      if (data.code === 200) {
        this.eventInfo = data.data
        if (this.roleFlag === '3') {
          if (this.eventInfo.startStatus === 1 && this.$route.path !== '/hostPage') {
            this.$router.push('/hostPage')
          }
          if (this.eventInfo.startStatus === 2 && this.$route.path !== '/drawControl') {
            this.$router.push('/drawControl')
          }
          if (this.eventInfo.startStatus === 3 && this.$route.path !== '/eventControl') {
            this.$router.push('/eventControl')
          }
          if (this.eventInfo.startStatus === 4 && this.$route.path !== '/eventOver') {
            this.$router.push('/eventOver')
          }
        }
        if (this.roleFlag === '4') {
          if (this.eventInfo.startStatus === 1 && this.$route.path !== '/beforeShow') {
            this.$router.push('/beforeShow')
          }
          if (this.eventInfo.startStatus === 2 && this.$route.path !== '/drawSort') {
            this.$router.push('/drawSort')
          }
          if (this.eventInfo.startStatus === 3 && this.$route.path !== '/eventShow') {
            this.$router.push('/eventShow')
          }
          if (this.eventInfo.startStatus === 4 && this.$route.path !== '/showOver') {
            this.$router.push('/showOver')
          }
        }
      }
    }
  }
}
</script>

<style scoped lang="less">
.contain {
  width: 100%;
  height: 100%;
  

  .contain-swiper {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat  no-repeat;
    background-size: 100% 100%;
    background-attachment: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    .swiper-header {
      width: 40%;
      height: 80px;
      box-sizing: border-box;
      margin-bottom: 30px;
      background: url("../../assets/images/new1.png") no-repeat center;
      background-size: contain;
      text-align: center;
      line-height: 80px;
      color: var(--theme_font-color);
      font-size: 45px;
      font-weight: bold;
    }
  
    .swiper-center {
      width: 80%;
      height: calc(100% - 200px);
      /*background-color: red;*/
      background: url("../../assets/images/new2.png") no-repeat;
      background-size: 100% 100%;
      padding: 3%;
    
      .center-title {
        width: 100%;
        color: var(--theme_font-color);
        height: 100px;
        font-size: 30px;
        line-height: 100px;
        font-family: "楷体";
        font-weight: bold;
      }
    
      .content-student {
        width: 100%;
        height: calc(100% - 100px);
        overflow: auto;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-wrap: wrap;
      
        .student-box {
          /*width: 200px;*/
          /*height: 200px;*/
          margin: 0 0 50px 0;
          padding: 20px;
          box-sizing: border-box;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-between;
        
          img {
            width: 70%;
            height: 70%;
            border-radius: 50%;
          }
          .active{
            width: 70%;
            height: 70%;
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            font-size: 20px;
            color: #f7f01b;
            font-family: "楷体";
          }
          .text-box {
            width: 100%;
            height: 30%;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 20px;
            color: var(--theme_font-color);
            font-family: "楷体";
          }
        }
      }
    
      .center-body {
        margin-top: 20px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-around;
      
        img {
          width: 150px;
          height: 150px;
          border-radius: 50%;
        }
      
        .body-text {
          width: 50%;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
          color: var(--theme_font-color);
          font-size: 22px;
          font-family: "楷体";
          font-weight: bold;
        
          span {
            margin-bottom: 20px;
          }
        }
      }
    }
    
  }
}
</style>
